.scroller {
    &__direction {
        display: none;
    }
}

// *** Media Queries ***
@include mq(medium) {
    .scroller {
        &__navigation {
            position: fixed;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);

            .scroller__item+.scroller__item {
                margin-top: 10px;
            }
        }

        &__item {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: $colorGray;
            opacity: 0.5;
            cursor: pointer;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);

            &:hover {
                opacity: 0.8;
            }

            &-list {
                display: flex;
                flex-direction: column;
                align-items: center;
                list-style: none;
                width: 14px;
            }

            &--is-active {
                width: 12px;
                height: 12px;
                background-color: $colorWhite;
                opacity: 1;
            }
        }

        &__direction {
            position: fixed;
            @include box (20px, 100%);
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            align-content: center;

            span {
                display: inline-block;
                color: white;
                writing-mode: tb-rl;
                transform: rotate(-180deg);
                text-transform: uppercase;
                letter-spacing: 0.45rem;
                font-size: 0.9rem;
                opacity: 0.3;
                padding: 30px 0;
                text-align: center;
                word-spacing: -1;
            }
        }
    }
}
body {
    font-family: $fontBase;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
}

h1 {
    font-size: 6rem;
    font-weight: 700;
}

h2 {
    //font-family: $fontAccent;
    //font-size: 1rem;
    // line-height: 1.2rem;
    //letter-spacing: 0.35rem;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 2px;
    line-height: 20px;
    word-spacing: 4px;
}

.paragraph {
    font-size: 1rem;
    line-height: 1.35rem;
    letter-spacing: 0px;
    font-weight: 400;
    text-align: justify;
    color: $colorDirtyWhite;

    &__quotation {
        font-size: 1.2rem;
        line-height: 1.7rem;
        letter-spacing: .1rem;
        font-weight: 400;
        color: $colorDirtyWhite;
        text-align: center;
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    }

    &--theme-accent {
        color: $colorGray;
    }

    & strong {
        font-weight: 700;
        color: $colorAccent;
        opacity: 0.7;
    }
}

// *** Media Queries ***
@include mq(xlarge) {
    .paragraph {
        &__quotation {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
}

@include mq (xxlarge) {
    .paragraph {
        font-size: 1.3rem;
        line-height: 1.6rem;
        letter-spacing: 0.05rem;

        &__quotation {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }
    }
}
.contact {
    &__content {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        color: $colorDirtyWhite;
        padding: 20px 0;
    }

    &__text {
        @include box (100%);
        padding: 20px 0;
    }

    &__form {
        @include box (100%);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        padding-bottom: 20px;
        margin-bottom: 20px;
        background-color: rgba(0, 0, 0, 0.03);
    }

    &__title {
        padding-top: 20px;
        font-size: 2rem;
        line-height: 2.2rem;
        letter-spacing: .1rem;
        font-weight: 900;
        color: #E8E6CE;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    }

    &__subtitle {
        padding: 5px 0 10px;
        letter-spacing: .1rem;
        font-size: 0.8rem;
        line-height: 0.8rem;
        font-weight: 700;
        text-align: center;
        color: $colorDirtyWhite;
        text-transform: uppercase;
    }

    p {
        text-align: center;
    }
}

.social-media {
    padding-top: 20px;

    &__item {
        padding: 10px 20px;
        color: $colorGray;

        &-list {
            @include box (100%);
            display: flex;
            justify-content: center;
        }
    }

    &__link {
        font-size: 1.1rem;
        color: $colorGray;
        text-decoration: none;
        transition: all 500ms ease-in-out;

        &:visited,
        &:active {
            text-decoration: none;
            color: $colorGray;
        }

        &:hover {
            color: $colorAccent;
            opacity: 0.7;
        }
    }
}

.form {
    margin-top: 20px;
    color: $colorGray;

    &__row {
        @include box (100%);
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        margin-bottom: 8px;
    }

    &__control {
        @include box (95%);
        display: flex;
        flex-flow: column;
        padding: 5px;
        text-align: left;
    }

    &__button {
        @include box (100%);
        display: flex;
        justify-content: center;
    }

    &__textarea {
        max-height: 150px;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        border-radius: 4px;
        transition: border-color 500ms ease;
        color: $colorGray;

        &:hover,
        &:focus {
            border: 1px solid rgba($colorAccent, 0.8);
        }
    }

    &__input {
        padding: 10px 12px;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        transition: border-color 500ms ease;
        color: $colorGray;
        font-size: 1rem;

        &:hover,
        &:focus {
            border: 1px solid rgba($colorAccent, 0.8);
        }
    }

    &__label {
        margin-bottom: 8px;
        color: $colorAccent;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 2px;
        text-transform: uppercase;
        opacity: 0.7;
    }
}

.message {

    &__success,
    &__fail {
        display: none;

        &--is-visible {
            display: block;
        }
    }
}

// *** Media Queries ***
@include mq(large) {
    .contact {
        &__content {
            @include box (100%);
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            color: $colorDirtyWhite;
            padding: 0;
        }

        &__text {
            @include box (45%, 100%);
            padding: 20px;
        }

        &__form {
            @include box (45%, 100%);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
            padding: 20px;
            margin-bottom: 0px;
            background-color: rgba(0, 0, 0, 0.03);
        }

        &__title {
            padding-top: 20px;
            font-size: 2rem;
            line-height: 2.2rem;
            letter-spacing: .1rem;
            font-weight: 900;
            color: #E8E6CE;
            text-align: center;
            text-transform: uppercase;
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        }

        &__subtitle {
            padding: 5px 0 10px;
            letter-spacing: .1rem;
            font-size: 0.8rem;
            line-height: 0.8rem;
            font-weight: 700;
            text-align: center;
            color: $colorDirtyWhite;
            text-transform: uppercase;
        }

        p {
            text-align: center;
        }
    }

    .social-media {
        padding-top: 20px;

        &__item {
            padding: 10px 20px;
            color: $colorGray;

            &-list {
                @include box (100%);
                display: flex;
                justify-content: center;
            }
        }

        &__link {
            font-size: 1.1rem;
            color: $colorGray;
            text-decoration: none;
            transition: all 500ms ease-in-out;

            &:visited,
            &:active {
                text-decoration: none;
                color: $colorGray;
            }

            &:hover {
                color: $colorAccent;
                opacity: 0.7;
            }
        }
    }

    .form {
        margin-top: 20px;
        color: $colorGray;

        &__row {
            @include box (100%);
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            margin-bottom: 8px;
        }

        &__control {
            @include box (100%);
            display: flex;
            flex-flow: column;
            padding: 5px;
            text-align: left;
        }

        &__button {
            @include box (100%);
            display: flex;
            justify-content: center;
        }

        &__textarea {
            max-height: 150px;
            background-color: rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            transition: border-color 500ms ease;
            color: $colorGray;

            &:hover,
            &:focus {
                border: 1px solid rgba($colorAccent, 0.8);
            }
        }

        &__input {
            padding: 10px 12px;
            background-color: rgba(0, 0, 0, 0.1);
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
            transition: border-color 500ms ease;
            color: $colorGray;
            font-size: 1rem;

            &:hover,
            &:focus {
                border: 1px solid rgba($colorAccent, 0.8);
            }
        }

        &__label {
            margin-bottom: 8px;
            color: $colorAccent;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 2px;
            text-transform: uppercase;
            opacity: 0.7;
        }
    }

    .message {

        &__success,
        &__fail {
            display: none;

            &--is-visible {
                display: block;
            }
        }
    }
}

@include mq(xxlarge) {
    .contact {
        &__title {
            font-size: 2.4rem;
            line-height: 2.6rem;
            letter-spacing: .1rem;
        }

        &__subtitle {
            font-size: 1rem;
            line-height: 1rem;
        }
    }
}
.top {
    display: block;
    position: fixed;
    max-width: 100vw;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
}

// *** Navbar ***
.navbar {
    height: 50px;
    max-width: 100vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }

    &__brand {
        @include background ($logo, no-repeat, cover, center);
        @include box (7.78rem, 1rem);
        align-self: center;
        z-index: 3;
    }

    &__button {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        z-index: 3;
    }
}

// *** Menu ***
.menu {
    &__item-list {
        @include box (100vw, 100vh);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        background: $colorDarkNevyBlue;
        transform: translateX(-100%);
        transition: all 1s ease-in-out;

        &--is-active {
            transform: translateX(0%);
        }
    }

    &__item {
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 40px;
        line-height: 40px;
        font-weight: 900;
        color: $colorWhite;
        letter-spacing: .4rem;
        text-transform: uppercase;
        cursor: pointer;
        opacity: 0;

        &--is-active {
            color: $colorAccent;
        }

        &-list {
            list-style: none;
        }
    }

    &__link {
        text-decoration: none;
    }
}

@keyframes navLinkFade {
    from {
        transform: translate3d(20px, 10px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
        transform-style: preserve-3d;
        opacity: 0;
        visibility: hidden;
    }

    to {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
        transform-style: preserve-3d;
        opacity: 1;
        visibility: visible;
    }
}

// *** Media Queries ***
@include mq(large) {
    .top {
        background: transparent;
    }

    .navbar {
        height: 70px;
        display: flex;
        align-items: center;
        align-content: center;

        &__wrapper {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }

        &__brand {
            @include background ($logo, no-repeat, cover, center);
            @include box (7.78rem, 1rem);
            align-self: center;
        }

        &__button {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            order: 3;
        }
    }

    // *** Menu ***
    .menu {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;

        &__item-list {
            display: flex;
            top: auto;
            bottom: auto;
            flex-flow: row nowrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            overflow-y: hidden;
            height: 0rem;
            transform: none;
            // transition: all 1s ease-in-out;
            background: transparent;

            &--is-active {
                height: 0.9rem;
            }
        }

        &__item {
            margin-left: 30px;
            font-size: 0.9rem;
            color: $colorWhite;
            font-weight: 400;
            letter-spacing: .4rem;
            text-transform: uppercase;
            cursor: pointer;
            opacity: 1;
            animation: none;

            &--is-active {
                color: $colorAccent;
            }

            &-list {
                list-style: none;
            }
        }

        &__link {
            text-decoration: none;
        }
    }
}
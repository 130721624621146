.portfolio {
    position: relative;
}

.projects {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    flex-flow: row nowrap;
    width: 300vw;
    height: 100vh;
    transition: transform 0.3s ease-out;
    cursor: grab;

    &--is-grab {
        cursor: grabbing;
        transition: transform 0.3s ease-out;
    }

    &--is-grab .project__img {
        transform: scale(0.9);
        transition: transform 0.3s ease-out;
    }
}

.project {
    max-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    align-content: center;
    user-select: none;

    &__menu {
        display: flex;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        padding: 10px;
    }

    &__info {
        flex-basis: 100%;
        display: flex;
        flex-flow: column nowrap;
    }

    &__heading {
        font-size: 1rem;
        letter-spacing: .4rem;
        line-height: 1.5rem;
        text-transform: uppercase;
        padding: 1vh 0 0.5vh 0;
        color: $colorDirtyWhite;
    }

    &__img {
        flex-basis: 100%;
        max-width: 300px;
        height: auto;
        filter: grayscale(80%);
        margin-bottom: 20px;
    }

    &__links {
        padding-top: 10px;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    &__link {
        flex-basis: 70%;
    }

    &__item {
        opacity: 0.7;
        font-weight: 700;

        &+.project__item {
            margin-left: 20px;
        }

        &--is-active {
            opacity: 1;
            font-size: 1.5rem;
            color: $colorDirtyWhite;
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
            ;
        }

        &-list {
            display: flex;
            list-style: none;
            color: $colorGray;
        }
    }
}

// *** Media Queries ***
@include mq(xlarge) {
    .projects {
        @include box (300vw, 100vh);
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        transition: transform 0.3s ease-out;
        cursor: grab;
        align-content: center;
        align-self: center;
    }

    .project {
        @include box (100vw, 100vh);
        transform: translateX(0px);

        &__wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;
        }

        &__info {
            flex-basis: 50%;

            &--reverse {
                order: -1;
            }
        }

        &__img {
            flex-basis: 45%;
            max-width: 500px;
            height: auto;
            filter: grayscale(80%);
            margin-bottom: 0px;
        }

        &__links {
            padding-top: 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            align-content: center;
        }

        &__link {
            flex-basis: 30%;
        }
    }
}

@include mq (xxlarge) {
    .project {
        &__img {
            max-width: 700px;
        }
    }
}
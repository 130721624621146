// *** Hamburger ***
.hamburger {
    width: 30px;
    height: 25px;
    display: block;
    margin: 0 0 0 1rem;
    cursor: pointer;

    &__line {
        height: 2px;
        margin: 5px 0;
        border-radius: 9px;
        transition: all ease 0.5s;
    }

    &__line-1 {
        width: 30px;
        background-color: $colorWhite;
    }

    &__line-2 {
        width: 20px;
        background-color: $colorDirtyWhite;
    }

    &__line-3 {
        width: 25px;
        background-color: $colorWhite;
    }

    &:hover {
        .hamburger {
            &__line-3 {
                width: 30px;
            }
        }
    }

    &--is-active {
        .hamburger {
            &__line-1 {
                transform: rotate(45deg) translate(5px, 5px);
            }

            &__line-2 {
                width: 0px;
                opacity: 0;
            }

            &__line-3 {
                background-color: $colorWhite;
                width: 30px;
                transform: rotate(-45deg) translate(5px, -5px);
            }
        }
    }
}
html {
    overflow: auto;
}

.section {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;

    &.contact {
        background: $colorDarkNevyBlue;
    }
}

.no-webp .section {
    @include background ($bg_460, no-repeat, cover, center, center);
}

.webp .section {
    @include background ($bg_460webp, no-repeat, cover, center, center);
}

.container {
    box-sizing: border-box;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    max-width: 540px;
}

.d-flex {
    display: flex;

    &__between {
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }

    &__center {
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    &__start {
        justify-content: flex-start;
        align-items: center;
        align-content: center;
    }
}

.heading-underline {
    width: 20%;
    max-height: 4px;
    max-width: 20%;
    min-height: 4px;
    border-radius: 30px;
    background-color: $colorAccent;
    margin: 20px auto 20px;
}

.icon {
    // margin-left: 10px;
    margin: 0 8px;
}

// *** Media Queries ***
@include mq(medium) {
    html {
        overflow: hidden;
    }

    .container {
        max-width: 720px;
    }

    .no-webp .section {
        @include background ($bg_800, no-repeat, cover, center, center);
    }

    .webp .section {
        @include background ($bg_800webp, no-repeat, cover, center, center);
    }
}

@include mq(large) {
    .container {
        max-width: 960px;
    }

    .no-webp .section {
        @include background ($bg_800, no-repeat, cover, center, center);
    }

    .webp .section {
        @include background ($bg_800webp, no-repeat, cover, center, center);
    }
}

@include mq(xlarge) {
    .container {
        max-width: 1140px;
    }

    .no-webp .section {
        @include background ($bg_1366, no-repeat, cover, center, center);
    }

    .webp .section {
        @include background ($bg_1366webp, no-repeat, cover, center, center);
    }
}

@include mq(xxlarge) {
    .container {
        max-width: 1520px;
    }

    .no-webp .section {
        @include background ($bg_1920, no-repeat, cover, center, center);
    }

    .webp .section {
        @include background ($bg_1920webp, no-repeat, cover, center, center);
    }
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%; }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none; }

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: 0; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0; }

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */ }

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */ }

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */ }

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */ }

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

html,
button,
input,
select,
textarea {
  color: #222; }

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none; }

::selection {
  background: #b3d4fc;
  text-shadow: none; }

img {
  vertical-align: middle; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

textarea {
  resize: vertical; }

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2rem; }

h1 {
  font-size: 6rem;
  font-weight: 700; }

h2 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 20px;
  word-spacing: 4px; }

.paragraph {
  font-size: 1rem;
  line-height: 1.35rem;
  letter-spacing: 0px;
  font-weight: 400;
  text-align: justify;
  color: #E8E6CE; }
  .paragraph__quotation {
    font-size: 1.2rem;
    line-height: 1.7rem;
    letter-spacing: .1rem;
    font-weight: 400;
    color: #E8E6CE;
    text-align: center;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); }
  .paragraph--theme-accent {
    color: #6e6f74; }
  .paragraph strong {
    font-weight: 700;
    color: #d0b17a;
    opacity: 0.7; }

@media (min-width: 1366px) {
  .paragraph__quotation {
    font-size: 1.5rem;
    line-height: 2rem; } }

@media (min-width: 1920px) {
  .paragraph {
    font-size: 1.3rem;
    line-height: 1.6rem;
    letter-spacing: 0.05rem; }
    .paragraph__quotation {
      font-size: 1.8rem;
      line-height: 2.3rem; } }

html {
  overflow: auto; }

.section {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%; }
  .section.contact {
    background: #0f1015; }

.no-webp .section {
  background-image: url("../images/bg_460.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: transparent; }

.webp .section {
  background-image: url("../images/bg_460.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: transparent; }

.container {
  box-sizing: border-box;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  max-width: 540px; }

.d-flex {
  display: flex; }
  .d-flex__between {
    justify-content: space-between;
    align-items: center;
    align-content: center; }
  .d-flex__center {
    justify-content: center;
    align-items: center;
    align-content: center; }
  .d-flex__start {
    justify-content: flex-start;
    align-items: center;
    align-content: center; }

.heading-underline {
  width: 20%;
  max-height: 4px;
  max-width: 20%;
  min-height: 4px;
  border-radius: 30px;
  background-color: #d0b17a;
  margin: 20px auto 20px; }

.icon {
  margin: 0 8px; }

@media (min-width: 768px) {
  html {
    overflow: hidden; }
  .container {
    max-width: 720px; }
  .no-webp .section {
    background-image: url("../images/bg_800.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: transparent; }
  .webp .section {
    background-image: url("../images/bg_800.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: transparent; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; }
  .no-webp .section {
    background-image: url("../images/bg_800.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: transparent; }
  .webp .section {
    background-image: url("../images/bg_800.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: transparent; } }

@media (min-width: 1366px) {
  .container {
    max-width: 1140px; }
  .no-webp .section {
    background-image: url("../images/bg_1366.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: transparent; }
  .webp .section {
    background-image: url("../images/bg_1366.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: transparent; } }

@media (min-width: 1920px) {
  .container {
    max-width: 1520px; }
  .no-webp .section {
    background-image: url("../images/bg_1920.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: transparent; }
  .webp .section {
    background-image: url("../images/bg_1920.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: transparent; } }

.scroller__direction {
  display: none; }

@media (min-width: 768px) {
  .scroller__navigation {
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }
    .scroller__navigation .scroller__item + .scroller__item {
      margin-top: 10px; }
  .scroller__item {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #6e6f74;
    opacity: 0.5;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.8); }
    .scroller__item:hover {
      opacity: 0.8; }
    .scroller__item-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      list-style: none;
      width: 14px; }
    .scroller__item--is-active {
      width: 12px;
      height: 12px;
      background-color: #FFFFFF;
      opacity: 1; }
  .scroller__direction {
    position: fixed;
    width: 20px;
    height: 100%;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .scroller__direction span {
      display: inline-block;
      color: white;
      writing-mode: tb-rl;
      transform: rotate(-180deg);
      text-transform: uppercase;
      letter-spacing: 0.45rem;
      font-size: 0.9rem;
      opacity: 0.3;
      padding: 30px 0;
      text-align: center;
      word-spacing: -1; } }

.intro__wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  align-content: center; }

.intro__img {
  width: auto;
  height: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  opacity: 0.05; }

.intro__title {
  color: white;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
  font-size: 120px;
  padding: 35px 10px; }

.intro__subtitle {
  font-size: 18px;
  margin-top: 30px;
  padding: 20px 10px;
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
  line-height: 26px;
  font-family: "Shippori Mincho B1", sans-serif;
  font-weight: 700; }

.accent {
  color: #d0b17a; }

.dash {
  display: inline-block;
  vertical-align: middle;
  height: 2px;
  width: 40px;
  background-color: #fff;
  border-radius: 10px;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8); }

@media (min-width: 992px) {
  .intro__img {
    width: auto;
    height: 450px;
    top: 50%;
    left: 50%;
    transform: translate(10%, -50%); }
  .intro__title {
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
    font-size: 180px;
    padding: 35px 10px; } }

@media (min-width: 1920px) {
  .intro__img {
    width: auto;
    height: 700px;
    top: 50%;
    left: 50%;
    transform: translate(10%, -50%); }
  .intro__title {
    text-shadow: 0 4px 9px rgba(0, 0, 0, 0.8);
    font-size: 240px;
    padding: 60px 18px; }
  .intro__subtitle {
    margin-top: 50px;
    font-size: 30px;
    padding: 30px 15px;
    color: white;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
    line-height: 50px; } }

.top {
  display: block;
  position: fixed;
  max-width: 100vw;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100; }

.navbar {
  height: 50px;
  max-width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center; }
  .navbar__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
  .navbar__brand {
    background-image: url("../images/logo.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0;
    background-color: transparent;
    width: 7.78rem;
    height: 1rem;
    align-self: center;
    z-index: 3; }
  .navbar__button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    z-index: 3; }

.menu__item-list {
  width: 100vw;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background: #0f1015;
  transform: translateX(-100%);
  transition: all 1s ease-in-out; }
  .menu__item-list--is-active {
    transform: translateX(0%); }

.menu__item {
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 40px;
  line-height: 40px;
  font-weight: 900;
  color: #FFFFFF;
  letter-spacing: .4rem;
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0; }
  .menu__item--is-active {
    color: #d0b17a; }
  .menu__item-list {
    list-style: none; }

.menu__link {
  text-decoration: none; }

@keyframes navLinkFade {
  from {
    transform: translate3d(20px, 10px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    transform-style: preserve-3d;
    opacity: 0;
    visibility: hidden; }
  to {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    transform-style: preserve-3d;
    opacity: 1;
    visibility: visible; } }

@media (min-width: 992px) {
  .top {
    background: transparent; }
  .navbar {
    height: 70px;
    display: flex;
    align-items: center;
    align-content: center; }
    .navbar__wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center; }
    .navbar__brand {
      background-image: url("../images/logo.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center 0;
      background-color: transparent;
      width: 7.78rem;
      height: 1rem;
      align-self: center; }
    .navbar__button {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      order: 3; }
  .menu {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center; }
    .menu__item-list {
      display: flex;
      top: auto;
      bottom: auto;
      flex-flow: row nowrap;
      align-items: center;
      align-content: center;
      justify-content: center;
      overflow-y: hidden;
      height: 0rem;
      transform: none;
      background: transparent; }
      .menu__item-list--is-active {
        height: 0.9rem; }
    .menu__item {
      margin-left: 30px;
      font-size: 0.9rem;
      color: #FFFFFF;
      font-weight: 400;
      letter-spacing: .4rem;
      text-transform: uppercase;
      cursor: pointer;
      opacity: 1;
      animation: none; }
      .menu__item--is-active {
        color: #d0b17a; }
      .menu__item-list {
        list-style: none; }
    .menu__link {
      text-decoration: none; } }

.hamburger {
  width: 30px;
  height: 25px;
  display: block;
  margin: 0 0 0 1rem;
  cursor: pointer; }
  .hamburger__line {
    height: 2px;
    margin: 5px 0;
    border-radius: 9px;
    transition: all ease 0.5s; }
  .hamburger__line-1 {
    width: 30px;
    background-color: #FFFFFF; }
  .hamburger__line-2 {
    width: 20px;
    background-color: #E8E6CE; }
  .hamburger__line-3 {
    width: 25px;
    background-color: #FFFFFF; }
  .hamburger:hover .hamburger__line-3 {
    width: 30px; }
  .hamburger--is-active .hamburger__line-1 {
    transform: rotate(45deg) translate(5px, 5px); }
  .hamburger--is-active .hamburger__line-2 {
    width: 0px;
    opacity: 0; }
  .hamburger--is-active .hamburger__line-3 {
    background-color: #FFFFFF;
    width: 30px;
    transform: rotate(-45deg) translate(5px, -5px); }

.btn {
  display: inline-block;
  transition: all 0.5s;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: none; }

.button {
  width: 70%;
  height: 70%;
  margin: 5px;
  padding: 16px 26px;
  text-transform: uppercase;
  color: #6e6f74;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: 1px;
  font-size: 0.8rem;
  border-radius: 4px;
  background: transparent;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: color 300ms ease;
  text-shadow: none;
  text-decoration: none;
  border: none; }
  .button:hover {
    color: #E8E6CE;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); }

.arrow {
  font-size: 0;
  z-index: 9; }
  .arrow__up, .arrow__up:hover, .arrow__up:focus {
    width: 17px;
    height: 81px;
    background: url("../images/arrow__up.png") no-repeat;
    opacity: 1;
    transition: all 400ms; }
  .arrow__down, .arrow__down:hover, .arrow__down:focus {
    width: 17px;
    height: 81px;
    background: url("../images/arrow__down.png") no-repeat;
    opacity: 1;
    transition: all 400ms; }
  .arrow__left, .arrow__left:hover, .arrow__left:focus {
    width: 40px;
    height: 17px;
    margin-right: 20px;
    background: url("../images/arrow__left.png") no-repeat;
    opacity: 1;
    transition: all 400ms; }
  .arrow__right, .arrow__right:hover, .arrow__right:focus {
    width: 40px;
    height: 17px;
    margin-left: 20px;
    background: url("../images/arrow__right.png") no-repeat;
    opacity: 1;
    transition: all 400ms; }
  .arrow__down--is-hidden, .arrow__up--is-hidden, .arrow__left--is-hidden, .arrow__right--is-hidden {
    opacity: 0;
    visibility: hidden;
    transition: all 400ms; }

@media (min-width: 992px) {
  .button {
    width: auto;
    height: auto;
    margin: 15px;
    padding: 16px 26px;
    text-transform: uppercase;
    color: #6e6f74;
    font-weight: 400;
    line-height: 1.1rem;
    letter-spacing: 1px;
    font-size: 0.8rem;
    border-radius: 4px;
    background: transparent;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    transition: color 300ms ease;
    text-shadow: none; }
    .button:hover {
      color: #E8E6CE;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); } }

.about {
  color: white; }
  .about__wrapper {
    width: 100%;
    display: flex;
    flex-flow: column-reverse; }
  .about__text {
    margin: 10px 0;
    flex-basis: 100%;
    width: 100%; }

.photo {
  margin: 6px;
  display: none; }
  .photo__img {
    max-width: 100%;
    filter: grayscale(80%); }
    .photo__img--visible-on-mobile {
      margin: 10px 0;
      flex-basis: 100%;
      display: block; }

@media (min-width: 992px) {
  .about__wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-content: space-between;
    align-items: stretch; }
  .about__text {
    align-self: center;
    flex-basis: 55%; }
  .about__pictures {
    padding-top: 30px;
    display: flex;
    flex-basis: 45%;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column; }
  .about__row {
    display: flex;
    width: 100%;
    justify-content: flex-end; }
  .photo {
    display: block;
    overflow: hidden;
    margin: 6px; }
    .photo__img {
      max-width: 100%;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      filter: grayscale(80%); }
      .photo__img--visible-on-mobile {
        display: none; }
    .photo__1, .photo__4 {
      max-width: 200px; }
    .photo__2 {
      max-width: 133px;
      align-self: center; }
    .photo__3 {
      max-width: 250px; } }

@media (min-width: 1920px) {
  .photo {
    margin: 10px; }
    .photo__1, .photo__4 {
      max-width: 280px; }
    .photo__2 {
      max-width: 186px; }
    .photo__3 {
      max-width: 350px; } }

.portfolio {
  position: relative; }

.projects {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-flow: row nowrap;
  width: 300vw;
  height: 100vh;
  transition: transform 0.3s ease-out;
  cursor: grab; }
  .projects--is-grab {
    cursor: grabbing;
    transition: transform 0.3s ease-out; }
  .projects--is-grab .project__img {
    transform: scale(0.9);
    transition: transform 0.3s ease-out; }

.project {
  max-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  align-content: center;
  user-select: none; }
  .project__menu {
    display: flex;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    padding: 10px; }
  .project__info {
    flex-basis: 100%;
    display: flex;
    flex-flow: column nowrap; }
  .project__heading {
    font-size: 1rem;
    letter-spacing: .4rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    padding: 1vh 0 0.5vh 0;
    color: #E8E6CE; }
  .project__img {
    flex-basis: 100%;
    max-width: 300px;
    height: auto;
    filter: grayscale(80%);
    margin-bottom: 20px; }
  .project__links {
    padding-top: 10px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    align-content: center; }
  .project__link {
    flex-basis: 70%; }
  .project__item {
    opacity: 0.7;
    font-weight: 700; }
    .project__item + .project__item {
      margin-left: 20px; }
    .project__item--is-active {
      opacity: 1;
      font-size: 1.5rem;
      color: #E8E6CE;
      text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8); }
    .project__item-list {
      display: flex;
      list-style: none;
      color: #6e6f74; }

@media (min-width: 1366px) {
  .projects {
    width: 300vw;
    height: 100vh;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    transition: transform 0.3s ease-out;
    cursor: grab;
    align-content: center;
    align-self: center; }
  .project {
    width: 100vw;
    height: 100vh;
    transform: translateX(0px); }
    .project__wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center; }
    .project__info {
      flex-basis: 50%; }
      .project__info--reverse {
        order: -1; }
    .project__img {
      flex-basis: 45%;
      max-width: 500px;
      height: auto;
      filter: grayscale(80%);
      margin-bottom: 0px; }
    .project__links {
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      align-content: center; }
    .project__link {
      flex-basis: 30%; } }

@media (min-width: 1920px) {
  .project__img {
    max-width: 700px; } }

.contact__content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  color: #E8E6CE;
  padding: 20px 0; }

.contact__text {
  width: 100%;
  height: 100%;
  padding: 20px 0; }

.contact__form {
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.03); }

.contact__title {
  padding-top: 20px;
  font-size: 2rem;
  line-height: 2.2rem;
  letter-spacing: .1rem;
  font-weight: 900;
  color: #E8E6CE;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); }

.contact__subtitle {
  padding: 5px 0 10px;
  letter-spacing: .1rem;
  font-size: 0.8rem;
  line-height: 0.8rem;
  font-weight: 700;
  text-align: center;
  color: #E8E6CE;
  text-transform: uppercase; }

.contact p {
  text-align: center; }

.social-media {
  padding-top: 20px; }
  .social-media__item {
    padding: 10px 20px;
    color: #6e6f74; }
    .social-media__item-list {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center; }
  .social-media__link {
    font-size: 1.1rem;
    color: #6e6f74;
    text-decoration: none;
    transition: all 500ms ease-in-out; }
    .social-media__link:visited, .social-media__link:active {
      text-decoration: none;
      color: #6e6f74; }
    .social-media__link:hover {
      color: #d0b17a;
      opacity: 0.7; }

.form {
  margin-top: 20px;
  color: #6e6f74; }
  .form__row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 8px; }
  .form__control {
    width: 95%;
    height: 95%;
    display: flex;
    flex-flow: column;
    padding: 5px;
    text-align: left; }
  .form__button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center; }
  .form__textarea {
    max-height: 150px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    transition: border-color 500ms ease;
    color: #6e6f74; }
    .form__textarea:hover, .form__textarea:focus {
      border: 1px solid rgba(208, 177, 122, 0.8); }
  .form__input {
    padding: 10px 12px;
    background-color: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    transition: border-color 500ms ease;
    color: #6e6f74;
    font-size: 1rem; }
    .form__input:hover, .form__input:focus {
      border: 1px solid rgba(208, 177, 122, 0.8); }
  .form__label {
    margin-bottom: 8px;
    color: #d0b17a;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    opacity: 0.7; }

.message__success, .message__fail {
  display: none; }
  .message__success--is-visible, .message__fail--is-visible {
    display: block; }

@media (min-width: 992px) {
  .contact__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: #E8E6CE;
    padding: 0; }
  .contact__text {
    width: 45%;
    height: 100%;
    padding: 20px; }
  .contact__form {
    width: 45%;
    height: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    margin-bottom: 0px;
    background-color: rgba(0, 0, 0, 0.03); }
  .contact__title {
    padding-top: 20px;
    font-size: 2rem;
    line-height: 2.2rem;
    letter-spacing: .1rem;
    font-weight: 900;
    color: #E8E6CE;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); }
  .contact__subtitle {
    padding: 5px 0 10px;
    letter-spacing: .1rem;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 700;
    text-align: center;
    color: #E8E6CE;
    text-transform: uppercase; }
  .contact p {
    text-align: center; }
  .social-media {
    padding-top: 20px; }
    .social-media__item {
      padding: 10px 20px;
      color: #6e6f74; }
      .social-media__item-list {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center; }
    .social-media__link {
      font-size: 1.1rem;
      color: #6e6f74;
      text-decoration: none;
      transition: all 500ms ease-in-out; }
      .social-media__link:visited, .social-media__link:active {
        text-decoration: none;
        color: #6e6f74; }
      .social-media__link:hover {
        color: #d0b17a;
        opacity: 0.7; }
  .form {
    margin-top: 20px;
    color: #6e6f74; }
    .form__row {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      margin-bottom: 8px; }
    .form__control {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;
      padding: 5px;
      text-align: left; }
    .form__button {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center; }
    .form__textarea {
      max-height: 150px;
      background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
      border-radius: 4px;
      transition: border-color 500ms ease;
      color: #6e6f74; }
      .form__textarea:hover, .form__textarea:focus {
        border: 1px solid rgba(208, 177, 122, 0.8); }
    .form__input {
      padding: 10px 12px;
      background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
      transition: border-color 500ms ease;
      color: #6e6f74;
      font-size: 1rem; }
      .form__input:hover, .form__input:focus {
        border: 1px solid rgba(208, 177, 122, 0.8); }
    .form__label {
      margin-bottom: 8px;
      color: #d0b17a;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;
      opacity: 0.7; }
  .message__success, .message__fail {
    display: none; }
    .message__success--is-visible, .message__fail--is-visible {
      display: block; } }

@media (min-width: 1920px) {
  .contact__title {
    font-size: 2.4rem;
    line-height: 2.6rem;
    letter-spacing: .1rem; }
  .contact__subtitle {
    font-size: 1rem;
    line-height: 1rem; } }

.intro {
    &__wrapper {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    &__img {
        @include box (auto, 350px);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        opacity: 0.05;
    }

    &__title {
        color: white;
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
        font-size: 120px;
        padding: 35px 10px;
    }

    &__subtitle {
        font-size: 18px;
        margin-top: 30px;
        padding: 20px 10px;
        color: white;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
        line-height: 26px;
        font-family: $fontAccent;
        font-weight: 700;
    }
}

.accent {
    color: $colorAccent;
}

.dash {
    display: inline-block;
    vertical-align: middle;
    height: 2px;
    width: 40px;
    background-color: #fff;
    border-radius: 10px;
    text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
}

// *** Media Queries ***
@include mq(large) {
    .intro {
        &__img {
            @include box (auto, 450px);
            top: 50%;
            left: 50%;
            transform: translate(10%, -50%);
        }

        &__title {
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.8);
            font-size: 180px;
            padding: 35px 10px;
        }
    }
}

@include mq(xxlarge) {
    .intro {
        &__img {
            @include box (auto, 700px);
            top: 50%;
            left: 50%;
            transform: translate(10%, -50%);
        }

        &__title {
            text-shadow: 0 4px 9px rgba(0, 0, 0, 0.8);
            font-size: 240px;
            padding: 60px 18px;
        }

        &__subtitle {
            margin-top: 50px;
            font-size: 30px;
            padding: 30px 15px;
            color: white;
            text-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
            line-height: 50px;
        }
    }
}
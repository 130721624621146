.btn {
    display: inline-block;
    transition: all 0.5s;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    border: none;
}

.button {
    @include box (70%);
    margin: 5px;
    padding: 16px 26px;
    text-transform: uppercase;
    color: $colorGray;
    font-weight: 400;
    line-height: 1.1rem;
    letter-spacing: 1px;
    font-size: 0.8rem;
    border-radius: 4px;
    background: transparent;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    transition: color 300ms ease;
    text-shadow: none;
    text-decoration: none;
    border: none;

    &:hover {
        color: $colorDirtyWhite;
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }
}

// arrow 
.arrow {
    font-size: 0;
    z-index: 9;

    &__up,
    &__up:hover,
    &__up:focus {
        @include box (17px, 81px);
        background: url($arrow__up) no-repeat;
        opacity: 1;
        transition: all 400ms;
    }

    &__down,
    &__down:hover,
    &__down:focus {
        @include box (17px, 81px);
        background: url($arrow__down) no-repeat;
        opacity: 1;
        transition: all 400ms;
    }

    &__left,
    &__left:hover,
    &__left:focus {
        @include box (40px, 17px);
        margin-right: 20px;
        background: url($arrow__left) no-repeat;
        opacity: 1;
        transition: all 400ms;
    }

    &__right,
    &__right:hover,
    &__right:focus {
        @include box (40px, 17px);
        margin-left: 20px;
        background: url($arrow__right) no-repeat;
        opacity: 1;
        transition: all 400ms;
    }

    &__down,
    &__up,
    &__left,
    &__right {
        &--is-hidden {
            opacity: 0;
            visibility: hidden;
            transition: all 400ms;
        }
    }
}

// *** Media Queries ***
@include mq(large) {
    .button {
        @include box (auto);
        margin: 15px;
        padding: 16px 26px;
        text-transform: uppercase;
        color: $colorGray;
        font-weight: 400;
        line-height: 1.1rem;
        letter-spacing: 1px;
        font-size: 0.8rem;
        border-radius: 4px;
        background: transparent;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        transition: color 300ms ease;
        text-shadow: none;

        &:hover {
            color: $colorDirtyWhite;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        }
    }
}
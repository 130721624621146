.about {
    color: white;

    &__wrapper {
        width: 100%;
        display: flex;
        flex-flow: column-reverse;
    }

    &__text {
        margin: 10px 0;
        flex-basis: 100%;
        width: 100%;
    }
}

.photo {
    margin: 6px;
    display: none;

    &__img {
        max-width: 100%;
        filter: grayscale(80%);

        &--visible-on-mobile {
            margin: 10px 0;
            flex-basis: 100%;
            display: block;
        }
    }
}

// *** Media Queries ***
@include mq(large) {
    .about {
        &__wrapper {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-content: space-between;
            align-items: stretch;
        }

        &__text {
            align-self: center;
            flex-basis: 55%;
        }

        &__pictures {
            padding-top: 30px;
            display: flex;
            flex-basis: 45%;
            justify-content: flex-end;
            align-items: flex-end;
            flex-direction: column;
        }

        &__row {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
    }

    .photo {
        display: block;
        overflow: hidden;
        margin: 6px;

        &__img {
            max-width: 100%;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            filter: grayscale(80%);

            &--visible-on-mobile {
                display: none;
            }
        }

        &__1,
        &__4 {
            max-width: 200px;
        }

        &__2 {
            max-width: 133px;
            align-self: center;
        }

        &__3 {
            max-width: 250px;
        }
    }
}

@include mq(xxlarge) {
    .photo {
        margin: 10px;

        &__1,
        &__4 {
            max-width: 280px;
        }

        &__2 {
            max-width: 186px;
        }

        &__3 {
            max-width: 350px;
        }
    }
}
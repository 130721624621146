//
// *** Colors ***
//
$colorWhite:#FFFFFF;
$colorAccent: #d0b17a;
$colorDirtyWhite:#E8E6CE;
$colorGray: #6e6f74;
$colorDarkNevyBlue: #0f1015;
$colorRed: #B3291D;
//
// *** Fonts ***
$fontBase: ('Lato', sans-serif);
$fontAccent: ('Shippori Mincho B1', sans-serif);
//
// *** LOGO SVG ***
$logo: "../images/logo.svg";
//
// *** IMAGES ***
//
// ***  BG ***
$bg_460: "../images/bg_460.png";
$bg_800: "../images/bg_800.png";
$bg_1366: "../images/bg_1366.png";
$bg_1920: "../images/bg_1920.png";
// *** BG - webp ***
$bg_460webp: "../images/bg_460.webp";
$bg_800webp: "../images/bg_800.webp";
$bg_1366webp: "../images/bg_1366.webp";
$bg_1920webp: "../images/bg_1920.webp";
//Arrows
$arrow__up: "../images/arrow__up.png";
$arrow__down: "../images/arrow__down.png";
$arrow__left: "../images/arrow__left.png";
$arrow__right: "../images/arrow__right.png";